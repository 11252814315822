<template>
  <el-row justify="center">
    <el-col :md="18">
      <el-button type="primary" @click="$router.push({ name: 'items' })"
        >아이템 리스트로 돌아가기</el-button
      >

      <el-card :header="isEdit ? '아이템 수정' : '아이템 추가'">
        <el-row justify="center">
          <el-button
            type="danger"
            @click="handleDelete"
            :style="{
              marginBottom: '10px',
            }"
            >삭제</el-button
          >
        </el-row>
        <Form
          as="el-form"
          v-if="showForm"
          @submit="handleSubmit"
          :validation-schema="schema"
        >
          <el-image
            v-if="initialItem?.imageUrl"
            :src="initialItem?.imageUrl"
            style="width: 150px; height: 150px"
          ></el-image>
          <el-upload
            class="avatar-uploader"
            :action="`${this.domain}/mock`"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon"><plus /></el-icon>
          </el-upload>

          <el-select v-model="selectedCategoryId" placeholder="카테고리를 선택해주세요">
            <el-option
              v-for="category in categories"
              :key="category.id"
              :label="category.name"
              :value="category.id"
            />
          </el-select>

          <InputWithValidation
            label="이름"
            name="name"
            :initialValue="initialItem?.name"
          />
          <InputWithValidation
            label="설명"
            name="description"
            :initialValue="initialItem?.description"
            type="textarea"
            rows="5"
          />
          <InputWithValidation
            label="가격"
            name="price"
            :initialValue="initialItem?.price"
            type="number"
            min="0"
          />

          <el-row>
            <el-checkbox
              v-model="form.limited"
              label="한정 판매 여부"
              size="large"
            ></el-checkbox>
          </el-row>

          <InputWithValidation
            v-if="form.limited"
            label="재고량"
            name="stock"
            :initialValue="initialItem?.stock"
            type="number"
            min="0"
          />

          <el-button type="primary" native-type="submit" :loading="loading"
            >저장</el-button
          >
        </Form>
        <el-alert
          v-if="message"
          :title="message"
          :type="showForm ? 'error' : 'success'"
        />
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import http from "../http-common";
import { Form } from "vee-validate";
import * as yup from "yup";
import { Plus } from "@element-plus/icons-vue";
import InputWithValidation from "./InputWithValidation.vue";

import ItemService from "../services/ItemService";
import CategoryService from "../services/CategoryService";

export default {
  name: "ItemAddOrEdit",
  components: {
    Form,
    InputWithValidation,
    Plus,
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required("이름을 입력해주세요."),
      description: yup.string().nullable(),
      price: yup
        .number()
        .required("가격을 입력해주세요.")
        .min(0, "가격은 0 이상이어야 합니다."),
      stock: yup.number().min(0, "재고량은 0 이상이어야 합니다."),
    });

    return {
      itemId: undefined,
      showForm: false,
      loading: false,
      message: "",
      schema,
      initialItem: null,
      file: null,
      imageUrl: "",
      domain:
        process.env.NODE_ENV === "production"
          ? process.env.VUE_APP_DOMAIN_PROD
          : "http://localhost:8080",
      form: {
        limited: false,
      },
      selectedCategoryId: undefined,
    };
  },
  computed: {
    isEdit() {
      return !!this.$route.params.id;
    },
  },
  async created() {
    this.retrieveCategories();

    if (!this.$route.params.id) {
      this.showForm = true;
      return;
    }

    this.itemId = this.$route.params.id;
    try {
      const response = await ItemService.get(this.itemId);
      console.log(response.data);
      this.initialItem = response.data;
      this.form.limited = this.initialItem.limited;
      this.selectedCategoryId = this.initialItem.categoryId;
      this.showForm = true;
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    async handleSubmit(item) {
      item.limited = this.form.limited;
      if (!item.limited) {
        item.stock = 0;
      }

      this.message = "";
      this.loading = true;

      // Upload image to S3
      if (this.file) {
        let formData = new FormData();
        formData.append("file", this.file.raw);

        try {
          const response = await http.post("/upload", formData);
          console.log(response.data);
          item.imageUrl = `https://gomibanchan.s3.us-east-2.amazonaws.com/${this.file.name}`;
        } catch (e) {
          console.log(e);
        }
      }

      // Set category Id
      item.categoryId = this.selectedCategoryId;

      if (this.isEdit) {
        ItemService.update(this.$route.params.id, item)
          .then((response) => {
            console.log(response.data);
            this.message = "아이템이 수정되었습니다.";
            this.showForm = false;
            this.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.message =
              (e.response && e.response.data && e.response.data.message) ||
              e.message ||
              e.toString();
            this.showForm = true;
            this.loading = false;
          });
      } else {
        ItemService.create(item)
          .then((response) => {
            console.log(response.data);
            this.message = "아이템이 추가되었습니다.";
            this.showForm = false;
            this.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.message =
              (e.response && e.response.data && e.response.data.message) ||
              e.message ||
              e.toString();
            this.showForm = true;
            this.loading = false;
          });
      }
    },

    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.file = file;
    },

    beforeAvatarUpload(file) {
      const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];
      const MAX_FILE_SIZE_IN_MB = 2;

      if (!allowedFileTypes.includes(file.type)) {
        this.$message.error("jpeg, jpg, png 이미지만 올려주세요.");
        return false;
      }
      if (file.size / 1024 / 1024 > MAX_FILE_SIZE_IN_MB) {
        this.$message.error(
          `이미지 사이즈는 ${MAX_FILE_SIZE_IN_MB} MB가 넘지 않게 해주세요.`
        );
        return false;
      }

      return true;
    },

    async handleDelete() {
      try {
        await this.$confirm(
          "정말로 해당 아이템을 삭제하시겠습니까? (삭제시 복구가 불가합니다.)"
        );
        await ItemService.delete(this.itemId);
        this.$message.success("아이템이 삭제되었습니다.");
        this.$router.push({ name: "items" });
      } catch (e) {
        console.log(e);
        this.message = e?.response?.data;
      }
    },

    async retrieveCategories() {
      try {
        const resp = await CategoryService.getAll();
        this.categories = resp.data;
        console.log("categories: ", this.categories);
      } catch (e) {
        console.log(e);
      }
    }
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.avatar-uploader-icon svg {
  margin-top: 74px; /* (178px - 28px) / 2 - 1px */
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>